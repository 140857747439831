import Button from "components/basics/Button";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import StickyBottomBar from "components/StickyBottomBar";
import { supportEmail } from "const";
import { getAndFormatFirstDayOfNextMonthDate } from "helper/dateAndTimeHelper";
import { Trans, useTranslation } from "react-i18next";
import useStatefulNavigate from "hooks/useStatefulNavigate";

const PayoutInformationExplanation = () => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();

  const payoutDate = getAndFormatFirstDayOfNextMonthDate();

  const onBack = () => {
    navigate("/payout");
  };

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.BackButton />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("payout.payoutInformation")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container hasStickyBottom>
        <h2 className={"mb-5 mt-4 text-center font-serif text-4xl text-red-900"}>{t("payout.payoutInformation")}</h2>
        <div>
          <p>
            {t("payout.payoutInformationDescription", {
              date: payoutDate,
            })}
          </p>
        </div>
        <p className="mt-5">
          <Trans i18nKey="smallBusiness.questions">
            If you have any questions, feel free to contact us:
            <a href={`mailto:${supportEmail}`} className="underline">
              {supportEmail}
            </a>
          </Trans>
        </p>
      </Container>
      <StickyBottomBar>
        <Button text={t("smallBusiness.button")} onClick={onBack} />
      </StickyBottomBar>
    </Layout>
  );
};

export default PayoutInformationExplanation;
