import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import useMaloumOfficial from "hooks/useMaloumOfficial.hook";
import useStatefulNavigate from "hooks/useStatefulNavigate";

const useChat = () => {
  const { maloumClient } = useContext(MaloumClientContext);
  const navigate = useStatefulNavigate();
  const { maloumOfficial } = useMaloumOfficial();

  const createChat = async (member2Id: string) => {
    return await maloumClient.chats
      .create({ member2: member2Id })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        // TODO handle error
        return err;
      });
  };

  const openChat = async (userId: string, replace?: boolean) => {
    const chat = await createChat(userId);
    navigate(`/chat/${chat._id}`, { replace: replace ? true : false });
  };

  const openChatWithMaloumOfficial = async (replace?: boolean) => {
    const chat = await createChat(maloumOfficial!._id);
    navigate(`/chat/${chat._id}`, { replace: replace ? true : false });
  };

  return { openChat, openChatWithMaloumOfficial };
};

export default useChat;
