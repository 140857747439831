import classNames from "classnames";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { HiXMark } from "react-icons/hi2";
import { useLocation } from "react-router-dom";
import { TSelectedMedia } from "types/vault/selectedMedia.type";

type Props = {
  selectedMediaId: string;
  onRemoveMedia?: (id: string) => void;
  className?: string;
  "data-testid"?: string;
};
const MediaRemoveButton = ({ selectedMediaId, onRemoveMedia, className, "data-testid": dataTestId }: Props) => {
  const { state } = useLocation();
  const selectedMedia = (state.selectedMedia ?? []) as TSelectedMedia[];
  const navigate = useStatefulNavigate();

  const handleRemoveMedia = () => {
    if (onRemoveMedia) onRemoveMedia(selectedMediaId);
    else {
      navigate(".", {
        state: { ...state, selectedMedia: selectedMedia.filter((s) => s._id !== selectedMediaId) },
        replace: true,
      });
    }
  };

  return (
    <button
      className={classNames(
        "shrink-1 peer absolute right-2 top-2 z-20 flex h-6 w-6 items-center justify-center rounded-full bg-white",
        className,
      )}
      onClick={handleRemoveMedia}
      data-testid={dataTestId}
    >
      <HiXMark className="h-5 w-5 text-gray-500" />
    </button>
  );
};

export default MediaRemoveButton;
