import { ChatDto, PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import PopUp from "components/popup/PopUp";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useUserStore from "state/userState";
import BlockUserPopup from "./block/BlockUserPopup";

type Props = {
  chat: ChatDto;
  open: boolean;
  onClose: () => void;
  "data-testid"?: string;
};

const ChatSettingsPopup = ({ chat, open, onClose, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const [blockUserOpen, setBlockUserOpen] = useState(false);

  const options = [
    ...(user.isCreator
      ? [
          {
            title: t("chatSettings.addToList"),
            onClick: () => navigate("/lists/add/member", { state: { userIdToAdd: chat.chatPartner._id } }),
          },
        ]
      : []),
    {
      title: t("chatSettings.block.blockUser"),
      onClick: () => setBlockUserOpen(true),
    },
  ];

  if (blockUserOpen) {
    return (
      <BlockUserPopup
        chatId={chat._id}
        userId={chat.chatPartner._id}
        open={blockUserOpen}
        onClose={() => {
          setBlockUserOpen(false);
          onClose();
        }}
      />
    );
  }

  return (
    <PopUp
      isOpen={open}
      onClose={onClose}
      title={t("chatSettings.title") ?? ""}
      data-testid={dataTestId}
      options={options}
    />
  );
};

export default ChatSettingsPopup;
