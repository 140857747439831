import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import PayoutMethodCard from "components/molecules/card/PayoutMethodCard";
import { formatIBAN } from "helper/stringFormatHelper";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import useUserStore from "../../state/userState";
import Button from "../basics/Button";
import useStatefulNavigate from "hooks/useStatefulNavigate";

const AddEditPayoutMethod = () => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();

  const user = useUserStore<PrivateUserDto>((state) => state.user);

  const handleChangePayoutMethod = useCallback(() => navigate("/payout/method"), []);

  if (!user) {
    return (
      <div className={"py-2"}>
        <div className={"mb-3 h-4 w-32 rounded-full bg-gray-200"} />
        <div className={"flex animate-pulse gap-3"}>
          <div className={"flex flex-col"}>
            <div className={"mb-1 h-4 w-24 rounded-full bg-gray-200"} />
            <div className={"mb-1 h-4 w-24 rounded-full bg-gray-200"} />
            <div className={"mb-1 h-4 w-24 rounded-full bg-gray-200"} />
          </div>

          <div className={"flex w-48 flex-col"}>
            <div className={"w-42 mb-1 h-4 rounded-full bg-gray-200"} />
            <div className={"w-42 mb-1 h-4 rounded-full bg-gray-200"} />
            <div className={"w-42 mb-1 h-4 rounded-full bg-gray-200"} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="pb-10 pt-5">
      <h2 className="mb-1 text-base font-semibold">{t("earnings.payoutTo")}</h2>

      {user.payoutData && user.payoutData.bankAccount && user.payoutData.bankAccount.iban ? (
        <button className="w-full" onClick={handleChangePayoutMethod}>
          <PayoutMethodCard
            firstName={user.payoutData.bankAccount.firstName}
            lastName={user.payoutData.bankAccount.lastName}
            iban={formatIBAN(user.payoutData.bankAccount.iban)}
          />
        </button>
      ) : (
        <Button
          text={t("payout.addMethod")}
          onClick={handleChangePayoutMethod}
          data-testid={"add-payout-method-button"}
        />
      )}
    </div>
  );
};

export default AddEditPayoutMethod;
