import { createContext, ReactNode, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";
import { useFirstline } from "@first-line/firstline-react";
import { env } from "env";

const SocketContext = createContext<Socket | undefined>(undefined);
export default SocketContext;

export const SocketProvider = ({ children }: { children: ReactNode }) => {
  const { getAccessToken } = useFirstline();
  const [socket, setSocket] = useState<Socket>();

  const setUpWebsocket = async () => {
    const socket = await connectWebSocket();
    setSocket(socket);

    socket.on("connect", onConnect(socket));
    return socket;
  };

  const connectWebSocket = async () => {
    return io(env.VITE_NEOLIME_WS_BACKEND_URL, {
      upgrade: true,
      transports: ["websocket"],
      auth: {
        authorization: `Bearer ${await getAccessToken()}`,
      },
    });
  };

  const onConnect = (socket: Socket) => () => {
    socket?.emit("authenticate", "ack");
  };

  useEffect(() => {
    const socket = setUpWebsocket();

    return () => {
      socket.then((socket) => {
        socket.off("connect", onConnect(socket));
      });
    };
  }, []);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
