import { createContext } from "react";
import axios from "axios";
import { env } from "../env";
import { MaloumClient } from "@neolime-gmbh/api-gateway-client/MaloumClient";
import { TrackingServiceClient } from "@neolime-gmbh/tracking-service-client/TrackingServiceClient";
import { useFirstline } from "@first-line/firstline-react";

// @ts-ignore
const MaloumClientContext = createContext<{
  maloumClient: MaloumClient;
  trackingServiceClient: TrackingServiceClient;
}>();
export default MaloumClientContext;

export const MaloumClientProvider = ({ children }) => {
  const { isAuthenticated, getAccessToken } = useFirstline();

  const maloumClient = new MaloumClient({
    BASE: env.VITE_NEOLIME_BACKEND_URL,
    TOKEN: isAuthenticated ? () => getAccessToken() : undefined,
  });

  const trackingServiceClient = new TrackingServiceClient({
    BASE: env.VITE_NEOLIME_TRACKING_SERVICE_URL,
  });

  const requests = [];

  /**
   * TODO rethink and probably completely remove duplicate request handling!
   * To handle urls which have dynamic url segments like /users/search/:query, it's possible
   * to use a query paramater `maloumKey` which uniquely identifies the request.
   * Otherwise, we won't be able to identify same requests as the url is constantly changing.
   */
  axios.interceptors.request.use(
    (config) => {
      const controller = new AbortController();
      config.signal = controller.signal;

      // Check if the request is already in the queue
      const requestIndex = requests.findIndex(
        (request) => request.url === config.url || (request.key !== null && request.key === config.params?.maloumKey),
      );
      if (requestIndex > -1) {
        // Cancel the request
        requests[requestIndex].controller.abort();
        // Remove the request from the queue
        requests.splice(requestIndex, 1);
      }

      requests.push({
        url: config.url,
        params: config.params,
        controller: controller,
        key: config.params?.maloumKey || null,
      });

      // Remove maloumKey from params if present before sending request
      delete config.params?.maloumKey;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    function (response) {
      // Remove request
      const requestIndex = requests.findIndex((request) => request.url === response.config.url);
      if (requestIndex > -1) {
        requests.splice(requestIndex, 1);
      }

      return response;
    },
    function (error) {
      return Promise.reject(error);
    },
  );
  return (
    <MaloumClientContext.Provider value={{ maloumClient, trackingServiceClient }}>
      {children}
    </MaloumClientContext.Provider>
  );
};
