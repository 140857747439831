import { useFirstline } from "@first-line/firstline-react";
import GuestContext from "contexts/GuestContext";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { convertStringtoSha256Hash } from "../helper/encryptionHelper";
import Loading from "../pages/basics/Loading";
import Intro from "../pages/signup/Intro";
import useUserStore from "../state/userState";
import MaloumClientContext from "./MaloumClientContext";

const UserContext = createContext();
export default UserContext;

export const UserProvider = ({ children }) => {
  const { doExchangeOrRefresh } = useFirstline();
  const { maloumClient } = useContext(MaloumClientContext);
  const { trackedGuest } = useContext(GuestContext);
  const userState = useUserStore((state) => state.user);

  // state needed to send GTM generic_event only one time
  const [sentGTMEvent, setSentGTMEvent] = useState(false);

  const getUser = async () => {
    return await maloumClient.users
      .getMe()
      .then((res) => {
        const browserLanguage = (navigator.language || navigator.userLanguage).slice(0, 2);
        if (res.language !== browserLanguage)
          maloumClient.users.updateProfile({
            language: browserLanguage,
          });

        useUserStore.setState({
          user: {
            ...res,
            language: browserLanguage,
          },
        });
        return res;
      })
      .catch((err) => {
        return err;
      });
  };

  const setBirthday = async (birthday, hasCompletedSetup = true) => {
    return await maloumClient.users
      .setBirthday({
        birthday: birthday,
      })
      .then((res) => {
        useUserStore.setState({
          user: {
            ...res,
            introduction: {
              hasCompletedSetup: hasCompletedSetup,
            },
          },
        });
        return res;
      })
      .catch((err) => {
        return err;
      });
  };

  const setRole = async (role, hasCompletedSetup = true) => {
    return await maloumClient.users
      .setRole({ role: role, guestId: trackedGuest })
      .then(async (res) => {
        await doExchangeOrRefresh();
        await new Promise((r) => setTimeout(r, 100));
        useUserStore.setState({
          user: {
            ...res,
            introduction: {
              hasCompletedSetup: hasCompletedSetup,
            },
            firstOpening: true,
          },
        });
        return res;
      })
      .catch((err) => {
        return err;
      });
  };

  const updateProfile = async (data, hasCompletedSetup = true, firstOpening = false) => {
    return await maloumClient.users
      .updateProfile(data)
      .then(async (res) => {
        useUserStore.setState({
          user: {
            ...res,
            introduction: {
              hasCompletedSetup: hasCompletedSetup,
            },
            firstOpening: firstOpening,
          },
        });
        return res;
      })
      .catch((err) => {
        return err;
      });
  };

  const hasNotCompletedSetup = useMemo(() => {
    return userState ? !userState.introduction.hasCompletedSetup : false;
  }, [userState]);

  useEffect(() => {
    getUser();
  }, [hasNotCompletedSetup]);

  // Google Tag Manager: Generic Data Event
  // why is this event triggered here?
  // this event needs the user object, therefore it can't be triggered before this
  // and as this contexts possibly splits the view (Intro / Platform), it cant be triggered after this.
  useEffect(() => {
    if (userState && !sentGTMEvent) {
      const sendGenericDataEvent = async () => {
        setSentGTMEvent(true);
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        window.dataLayer.push({
          event: "generic_data",
          currency: "EUR",
          user: {
            email: userState.email,
            email_sha256: await convertStringtoSha256Hash(userState.email),
            username: userState.username,
            username_sha256: await convertStringtoSha256Hash(userState.username),
            id: userState._id,
            is_creator: userState.is_creator,
          },
        });
      };
      sendGenericDataEvent();
    }
  }, [userState]);

  return (
    <UserContext.Provider
      value={{
        getUser,
        setBirthday,
        setRole,
        updateProfile,
      }}
    >
      {userState ? hasNotCompletedSetup ? <Intro /> : children : <Loading />}
    </UserContext.Provider>
  );
};
