import { MessageContentDto, MessageDto, TextMessageContentDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";
import { ChatProductMessageContentDto } from "@neolime-gmbh/api-gateway-client/models/ChatProductMessageContentDto";
import type = TextMessageContentDto.type;
import DeleteForAllPopup from "./popups/DeleteForAllPopup";

type MenuItemProps = {
  Icon: React.ComponentType<{ className?: string }>;
  title: string;
  onClick: () => void;
};
const MenuItem = ({ Icon, title, onClick }: MenuItemProps) => {
  return (
    <button className="flex items-center gap-2 p-2" onClick={onClick}>
      <Icon className="h-6 w-6" />
      <div>{title}</div>
    </button>
  );
};

type Props = {
  message: MessageDto;
  chatPartnerId?: string;
  visible: boolean;
  onDelete: () => void;
  onDeleteBroadcasted: () => void;
  onEditPrice: () => void;
  className?: string;
  "data-testid"?: string;
};

const MessageMenuPopup = ({
  message,
  chatPartnerId,
  visible,
  onDelete,
  onDeleteBroadcasted,
  onEditPrice,
  className,
  "data-testid": dataTestId,
}: Props) => {
  const { t } = useTranslation();
  const [onBottom, setOnBottom] = useState(true);
  const [topOrBottomSet, setTopOrBottomSet] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const [deleteForAllPopupOpen, setDeleteForAllPopupOpen] = useState(false);

  // set positioning of popup if visible or reset if not visible
  useEffect(() => {
    if (ref.current) {
      if (
        window.innerHeight - ref.current.getBoundingClientRect().top - ref.current.getBoundingClientRect().height <
        30
      )
        setOnBottom(false);
      setTopOrBottomSet(true);
    }
    if (!visible) {
      setOnBottom(true);
      setTopOrBottomSet(false);
    }
  }, [visible]);

  // checks to show specific menu items
  // we can assume that if the message is from the current user
  // 1. message can be deleted
  const canDelete = useMemo(
    () =>
      // check if message is not a TIP or CHAT_PRODUCT that was bought
      message.content.type !== MessageContentDto.type.TIP &&
      !(
        message.content.type === type.CHAT_PRODUCT &&
        chatPartnerId &&
        (message.content as ChatProductMessageContentDto).purchasedBy?.includes(chatPartnerId)
      ),
    [message],
  );
  // 2. change chat product price
  const canChangePrice = useMemo(
    () =>
      // check if message is a CHAT_PRODUCT that was not bought
      message.content.type === MessageContentDto.type.CHAT_PRODUCT &&
      !(
        message.content.type === type.CHAT_PRODUCT &&
        chatPartnerId &&
        (message.content as ChatProductMessageContentDto).purchasedBy?.includes(chatPartnerId)
      ),
    [message],
  );
  // 3. delete broadcasted message for all
  const canDeleteBroadcasted = useMemo(
    () =>
      message.isBroadcasted &&
      !(
        message.content.type === type.CHAT_PRODUCT &&
        chatPartnerId &&
        (message.content as ChatProductMessageContentDto).purchasedBy?.includes(chatPartnerId)
      ),
    [message],
  );

  const menuItems = useMemo(
    () =>
      [
        canDelete && { Icon: HiOutlineTrash, title: t("delete"), onClick: onDelete },
        canDeleteBroadcasted && {
          Icon: HiOutlineTrash,
          title: t("deleteForAll"),
          onClick: () => setDeleteForAllPopupOpen(true),
        },
        canChangePrice && { Icon: HiOutlinePencil, title: t("changeChatProductPrice.editPrice"), onClick: onEditPrice },
      ].filter(Boolean) as MenuItemProps[],
    [],
  );

  return (
    <>
      <div
        className={classNames(
          "absolute grid w-48 grid-cols-1 gap-1 rounded-md bg-white p-2",
          className,
          { "-bottom-4 translate-y-full": onBottom, "-top-4 -translate-y-full": !onBottom },
          { hidden: !visible },
          { "opacity-0": !topOrBottomSet },
        )}
        ref={ref}
        data-testid={dataTestId}
      >
        {menuItems.map((item, idx) => (
          <>
            {idx !== 0 && <div className="h-px w-full rounded-full bg-gray-200" />}
            <MenuItem {...item} />
          </>
        ))}
      </div>
      <DeleteForAllPopup
        open={deleteForAllPopupOpen}
        onClose={() => setDeleteForAllPopupOpen(false)}
        onDelete={onDeleteBroadcasted}
      />
    </>
  );
};

export default MessageMenuPopup;
