import { RadioGroup } from "@headlessui/react";
import { PrivateUserDto, PublicPriceDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import Button from "components/basics/Button";
import RadioOption from "components/basics/RadioOption";
import SelectItemUnderline from "components/utilities/SelectItemUnderline";
import ValidationError from "components/utilities/ValidationError";
import useCurrency from "hooks/useCurrency.hook";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import useUserStore from "state/userState";

type Props = {
  price: PublicPriceDto | undefined;
  paymentMethods: { name: string; value: { paymentMethod: string; referenceId?: string }; logo: any }[];
  selectedPaymentMethod: { paymentMethod: string; referenceId?: string };
  setSelectedPaymentMethod: (paymentMethod: { paymentMethod: string; referenceId?: string }) => void;
  type: "SUBSCRIPTION" | "PRODUCT" | "TIP" | "CHAT_PRODUCT" | "CHAT_UNLOCK";
  selectedCountry: string | undefined;
  showCountrySelect: () => void;
  handleBuy: () => Promise<void>;
  className?: string;
  "data-testid"?: string;
};

const Payment = ({
  price,
  paymentMethods,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  type,
  selectedCountry,
  showCountrySelect,
  handleBuy,
  className,
  "data-testid": dataTestId,
}: Props) => {
  const { t } = useTranslation();
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const { displayCurrency } = useCurrency();

  const [showCountryNotSelectedError, setShowCountryNotSelectedError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleShowCountrySelect = () => {
    showCountrySelect();
    setShowCountryNotSelectedError(false);
  };

  const comparePaymentMethods = (
    pm1: { paymentMethod: string; referenceId?: string },
    pm2: { paymentMethod: string; referenceId?: string },
  ) => {
    if (pm1.referenceId || pm2.referenceId) return pm1.referenceId === pm2.referenceId;
    return pm1.paymentMethod === pm2.paymentMethod;
  };

  const handleOnClick = async () => {
    setLoading(true);
    // TODO: Handle error cases
    // Loading will only be disabled if there is an error with the payment endpoint or the redirect.
    // In all successful cases, the user will be redirected to the payment provider.
    try {
      if (!selectedCountry) {
        setShowCountryNotSelectedError(true);
        setLoading(false);
      } else await handleBuy();
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div className={classNames("", className)} data-testid={dataTestId}>
      <div className="text-2xl font-semibold">{t("checkout.payment")}</div>
      {/* Only show country select for product and tips if country was not selected yet */}
      {!user.address.country && type !== "PRODUCT" && (
        <div className="mt-3">
          <label className={"mb-2 text-sm text-gray-700"}>{`${t("address.country")} *`}</label>
          <SelectItemUnderline
            value={selectedCountry ? t(`countries.${selectedCountry}`) : t("selectCountry")}
            onClick={handleShowCountrySelect}
            data-testid={"checkout-country-select"}
          />
          {showCountryNotSelectedError && (
            <ValidationError message={t("selectCountryError")} hasBackground={false} className={"mt-2"} />
          )}
        </div>
      )}
      {/* TODO Radio Option is not correctly selected after reload */}
      <RadioGroup
        value={selectedPaymentMethod}
        onChange={setSelectedPaymentMethod}
        by={comparePaymentMethods}
        className="mt-4"
      >
        <div className="relative -space-y-px bg-white">
          {paymentMethods.map((payment) => (
            <RadioOption
              key={payment.value.referenceId ?? payment.value.paymentMethod}
              value={payment.value}
              title={payment.name}
              rightSlot={payment.logo}
            />
          ))}
        </div>
      </RadioGroup>
      <div className="h-[7rem]" />
      <div className="fixed bottom-0 left-0 mx-auto grid w-full grid-cols-2 items-center border-t border-t-gray-100 bg-white p-3 sm:left-auto sm:w-[calc(100%-5rem)] sm:max-w-xl sm:-translate-x-4 md:w-[calc(100%-15rem)]">
        {price && <span>{displayCurrency(price.gross)}</span>}
        {!price && <span className="h-5 w-16 rounded-md bg-gray-100" />}
        <Button
          onClick={handleOnClick}
          data-testid="proceed-checkout-button"
          text={!loading ? (t("buyNow") as string) : undefined}
          IconFront={loading ? () => <AiOutlineLoading3Quarters className="my-0.5 h-5 w-5 animate-spin" /> : undefined}
          disabled={loading || !price}
        />
      </div>
    </div>
  );
};

export default Payment;
