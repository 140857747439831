import MaloumClientContext from "contexts/MaloumClientContext";
import SocketContext from "contexts/SocketContext";
import { useContext, useEffect } from "react";
import useUnreadMessagesState from "state/unreadMessagesState";

const useUnreadChats = () => {
  const socket = useContext(SocketContext);
  const { unreadMessagesNumber } = useUnreadMessagesState();
  const { maloumClient } = useContext(MaloumClientContext);

  const addRefreshUnreadChatsEventToSocket = () => {
    socket?.on("refresh_unread_chats", (unreadChats: number) => {
      useUnreadMessagesState.setState({ unreadMessagesNumber: unreadChats });
    });
  };

  const updateUnreadMessages = async () => {
    const unreadChats = await maloumClient.chats.getUnreadChatsCount();
    useUnreadMessagesState.setState({ unreadMessagesNumber: unreadChats });
  };

  useEffect(() => {
    if (!socket) return;
    addRefreshUnreadChatsEventToSocket();
    updateUnreadMessages();

    return () => {
      socket.off("refresh_unread_chats");
    };
  }, [socket]);

  return { unreadMessagesNumber };
};

export default useUnreadChats;
