import { ChatDto, MessageDto } from "@neolime-gmbh/api-gateway-client";
import { create } from "zustand";
import { messageToMessagePreview } from "helper/messageHelper";

type TChatsStore = {
  chats: ChatDto[];
  markChatAsRead: (chatId: string) => void;
  updateMessageForChatIfExists: (chatId: string, message: MessageDto, markRead?: boolean) => void;
};

const useChatsStore = create<TChatsStore>((set) => ({
  chats: [] as ChatDto[],
  markChatAsRead: (chatId) =>
    set((state) => {
      const chatsTmp = [...state.chats];
      const chatIdx = chatsTmp.findIndex((c) => c._id === chatId);
      if (chatIdx === -1) return {};
      chatsTmp[chatIdx].unreadMessages = false;
      return { chats: chatsTmp };
    }),
  updateMessageForChatIfExists: (chatId, message, markRead = false) =>
    set((state) => {
      const chatsTmp = [...state.chats];
      const chatIdx = chatsTmp.findIndex((c) => c._id === chatId);
      if (chatIdx === -1) return {};
      chatsTmp[chatIdx].messages = [messageToMessagePreview(message)];
      chatsTmp[chatIdx].unreadMessages = !markRead;
      return { chats: chatsTmp };
    }),
}));

export default useChatsStore;
