import { ChatListDto, MessageContentDto, SentBroadcastDto } from "@neolime-gmbh/api-gateway-client";
import BroadcastCard from "components/organisms/broadcast/BroadcastCard";
import React, { useState } from "react";
import PopUp from "components/popup/PopUp";
import PillButton from "components/atoms/buttons/PillButton";
import { HiOutlineEye, HiOutlineUserGroup } from "react-icons/hi";
import { HiOutlineBanknotes } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import useSelectedListsOverviewNavigation from "hooks/navigation/useSelectedListsOverviewNavigation";
import Pill from "components/atoms/Pill";
import useSentBroadcasts from "hooks/chats/useSentBroadcasts.hook";
import Button from "components/basics/Button";
import useStatefulNavigate from "hooks/useStatefulNavigate";

type Props = {
  broadcast: SentBroadcastDto;
  className?: string;
};

const SentBroadcastCard = ({ broadcast, className }: Props) => {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useStatefulNavigate();

  const isChatProduct = broadcast.content.type === MessageContentDto.type.CHAT_PRODUCT;

  const navigateToUsersList = (userGroup: "receivedBy" | "viewedBy" | "boughtBy") => {
    let userCount = 0;
    switch (userGroup) {
      case "receivedBy":
        userCount = broadcast.recipientCount;
        break;
      case "viewedBy":
        userCount = broadcast.viewerCount;
        break;
      case "boughtBy":
        userCount = broadcast.buyerCount;
        break;
    }

    navigate("users", { state: { broadcastId: broadcast._id, userGroup, userCount } });
  };

  const labels = (
    <Labels
      recipientCount={broadcast.recipientCount}
      viewerCount={broadcast.viewerCount}
      buyerCount={broadcast.buyerCount}
      isChatProduct={isChatProduct}
      isRevoked={broadcast.isRevoked}
      onNavigateToUsers={navigateToUsersList}
    />
  );

  return (
    <>
      <BroadcastCard
        date={new Date(broadcast.processedAt)}
        text={broadcast.content.text}
        content={broadcast.content}
        onOpenPopup={() => setShowPopup(true)}
        labels={labels}
        className={className}
      />
      <ContextPopup
        show={showPopup}
        onClose={() => setShowPopup(false)}
        broadcastId={broadcast._id}
        isChatProduct={isChatProduct}
        isRevoked={broadcast.isRevoked}
        includeLists={broadcast.includedLists}
        excludeLists={broadcast.excludedLists}
        onNavigateToUsers={navigateToUsersList}
      />
    </>
  );
};

export default SentBroadcastCard;

type ContextPopupProps = {
  show: boolean;
  onClose: () => void;
  broadcastId: string;
  isChatProduct: boolean;
  isRevoked: boolean;
  includeLists: ChatListDto[];
  excludeLists: ChatListDto[];
  onNavigateToUsers: (userGroup: "receivedBy" | "viewedBy" | "boughtBy") => void;
};

const ContextPopup = ({
  show,
  onClose,
  broadcastId,
  isChatProduct,
  isRevoked,
  includeLists,
  excludeLists,
  onNavigateToUsers,
}: ContextPopupProps) => {
  const { t } = useTranslation();
  const navigateToSelectedListsOverview = useSelectedListsOverviewNavigation();

  const [deletePopupOpen, setDeletePopupOpen] = useState(false);

  const options = [
    ...(isChatProduct
      ? [
          {
            title: t("chats.broadcasts.seeBuyers"),
            onClick: () => onNavigateToUsers("boughtBy"),
          },
        ]
      : []),
    {
      title: t("chats.broadcasts.seeViewers"),
      onClick: () => onNavigateToUsers("viewedBy"),
    },
    {
      title: t("chats.broadcasts.seeRecipients"),
      onClick: () => onNavigateToUsers("receivedBy"),
    },
    {
      title: t("chats.broadcasts.seeSelectedLists"),
      onClick: () => navigateToSelectedListsOverview({ includeLists, excludeLists }),
    },
    ...(isRevoked
      ? []
      : [
          {
            title: isChatProduct ? t("chats.broadcasts.deleteForNonBuyers") : t("chats.broadcasts.deleteForAll"),
            onClick: () => setDeletePopupOpen(true),
          },
        ]),
  ];

  if (deletePopupOpen) {
    return (
      <DeletePopup
        show={deletePopupOpen}
        onClose={() => {
          setDeletePopupOpen(false);
          onClose();
        }}
        broadcastId={broadcastId}
        isChatProduct={isChatProduct}
      />
    );
  }

  return <PopUp isOpen={show} onClose={onClose} title={t("chats.broadcasts.popupTitle") ?? ""} options={options} />;
};

type DeletePopupProps = {
  show: boolean;
  onClose: () => void;
  broadcastId: string;
  isChatProduct: boolean;
};

const DeletePopup = ({ show, onClose, broadcastId, isChatProduct }: DeletePopupProps) => {
  const { t } = useTranslation();
  const { revokeBroadcastMutation } = useSentBroadcasts();

  const handleRevokeBroadcast = () => {
    revokeBroadcastMutation.mutate(broadcastId, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <PopUp isOpen={show} onClose={onClose} title={t("chats.broadcasts.delete") ?? ""}>
      <div className="mt-2">
        {isChatProduct ? t("chats.broadcasts.deleteConfirmationChatProduct") : t("chats.broadcasts.deleteConfirmation")}
      </div>
      <div className="mt-4 flex items-center gap-2">
        <Button text={t("chats.broadcasts.cancel")} variant="secondary" onClick={onClose} />
        <Button text={t("chats.broadcasts.delete")} onClick={handleRevokeBroadcast} />
      </div>
    </PopUp>
  );
};

type LabelsProps = {
  recipientCount: number;
  viewerCount: number;
  buyerCount: number;
  isChatProduct: boolean;
  isRevoked: boolean;
  onNavigateToUsers: (userGroup: "receivedBy" | "viewedBy" | "boughtBy") => void;
};

// TODO Links
const Labels = ({
  recipientCount,
  viewerCount,
  buyerCount,
  isChatProduct,
  isRevoked,
  onNavigateToUsers,
}: LabelsProps) => {
  const { t } = useTranslation();

  return (
    <>
      {isRevoked && (
        <Pill colors="bg-gray-100" size="sm" className="w-fit">
          {t("chats.broadcasts.recalled")}
        </Pill>
      )}
      <div className="flex gap-2">
        <PillButton onClick={() => onNavigateToUsers("receivedBy")}>
          <HiOutlineUserGroup className="h-4 w-4" />
          {recipientCount}
        </PillButton>
        <PillButton onClick={() => onNavigateToUsers("viewedBy")}>
          <HiOutlineEye className="h-4 w-4" />
          {viewerCount}
        </PillButton>
        {isChatProduct && (
          <PillButton onClick={() => onNavigateToUsers("boughtBy")}>
            <HiOutlineBanknotes className="h-4 w-4" />
            {buyerCount}
          </PillButton>
        )}
      </div>
    </>
  );
};
