import classNames from "classnames";
import TernaryButton from "components/atoms/buttons/TernaryButton";
import TernaryLink from "components/atoms/links/TernaryLink";
import useScrollPosition from "hooks/useScrollPosition.hook";
import { ReactNode } from "react";
import { HiChevronLeft } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useLocation } from "react-router-dom";

type HeaderBarDefaultProps = {
  children?: ReactNode;
  className?: string;
  "data-testid"?: string;
};

type HeaderBarProps = HeaderBarDefaultProps & {
  showDividerOnDesktop?: boolean;
  possitionClassNames?: string;
  alwaysShowDividerXL?: boolean;
};

type HeaderBarButtonProps = HeaderBarDefaultProps & {
  onClick: () => void;
};

type HeaderBarLinkProps = HeaderBarDefaultProps & {
  to: string;
};

const HeaderBar = ({
  children,
  className,
  showDividerOnDesktop = true,
  possitionClassNames,
  alwaysShowDividerXL = false,
}: HeaderBarProps) => {
  const { isAtPageTop } = useScrollPosition();
  return (
    <header
      className={classNames(
        {
          "border-gray-100": !isAtPageTop,
          "border-transparent": isAtPageTop,
          "xl:border-gray-100": alwaysShowDividerXL,
          "sm:border-transparent": !showDividerOnDesktop,
          sticky: !possitionClassNames,
        },
        possitionClassNames,
        "top-0 z-50 mx-auto w-full max-w-[100vw] border-b bg-white sm:max-w-xl",
        className,
      )}
    >
      {children}
    </header>
  );
};

HeaderBar.MainPage = ({ children, className }: HeaderBarDefaultProps) => (
  <div className={classNames("flex justify-between px-4 pt-2 sm:pt-6", className)}>{children}</div>
);

HeaderBar.MainPageHeading = ({ children, className }: HeaderBarDefaultProps) => (
  <h1 className={classNames("py-2.5 text-2xl font-semibold leading-6 text-gray-900", className)}>{children}</h1>
);

HeaderBar.MainPageActions = ({ children, className }: HeaderBarDefaultProps) => (
  <div className={classNames("-mr-2.5 flex md:gap-x-1", className)}>{children}</div>
);

HeaderBar.SubPage = ({ children, className }: HeaderBarDefaultProps) => (
  <div className={classNames("flex items-center justify-between bg-white px-1.5 pt-2 sm:pt-5", className)}>
    {children}
  </div>
);

HeaderBar.Left = ({ children, className }: HeaderBarDefaultProps) => (
  <div className={classNames("-ml-2 flex basis-1/2", className)}>{children}</div>
);

HeaderBar.Center = ({ children, className }: HeaderBarDefaultProps) => (
  <div className={classNames("min-w-[12rem]", className)}>{children}</div>
);

HeaderBar.Right = ({ children, className }: HeaderBarDefaultProps) => (
  <div className={classNames("-mr-2 flex basis-1/2 justify-end", className)}>{children}</div>
);

HeaderBar.Title = ({ children, className }: HeaderBarDefaultProps) => (
  <h1 className={classNames("flex min-h-[2.5rem] items-center justify-center", className)}>
    <div className="line-clamp-1 text-center text-sm">{children}</div>
  </h1>
);

type HeaderBarBackButtonProps = {
  onClick?: () => void;
  fallbackRoute?: string;
} & HeaderBarDefaultProps;

HeaderBar.BackButton = ({ onClick, fallbackRoute, className }: HeaderBarBackButtonProps) => {
  const navigate = useStatefulNavigate();
  const location = useLocation();

  const handleClick = () => {
    const useFallback = location.state?.forceFallback || location.state?.host !== window.location.hostname;

    if (onClick) onClick();
    else if (fallbackRoute && useFallback) navigate(fallbackRoute);
    else navigate(-1);
  };

  return (
    <TernaryButton onClick={handleClick} className={classNames("shrink-0", className)}>
      <HiChevronLeft className={"h-6 w-6"} />
    </TernaryButton>
  );
};

type HeaderBarCancelProps = { onClick?: () => void } & HeaderBarDefaultProps;

HeaderBar.Cancel = ({ onClick, className }: HeaderBarCancelProps) => {
  const navigate = useStatefulNavigate();
  const { t } = useTranslation();
  return (
    <TernaryButton
      data-testid="button-cancel"
      onClick={() => (onClick ? onClick() : navigate(-1))}
      className={classNames("ml-2 shrink-0 text-sm text-gray-700", className)}
    >
      {t("cancel")}
    </TernaryButton>
  );
};

HeaderBar.Button = ({ onClick, children, className, "data-testid": dataTestId }: HeaderBarButtonProps) => (
  <TernaryButton data-testid={dataTestId} onClick={onClick} className={classNames("shrink-0", className)}>
    {children}
  </TernaryButton>
);

HeaderBar.Link = ({ to, children, className }: HeaderBarLinkProps) => {
  return (
    <TernaryLink to={to} className={classNames("shrink-0", className)}>
      {children}
    </TernaryLink>
  );
};

export default HeaderBar;
