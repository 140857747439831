import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useQuery } from "react-query";

const useChatItem = (id: string) => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchChat = async () => await maloumClient.chats.getChat(id);

  // only fetch every 10 minutes max
  const {
    data: chat,
    isLoading,
    isError,
  } = useQuery(["fetch-chat", id], fetchChat, { staleTime: 10 * 60 * 10000 /* 10 minutes */ });

  return {
    chat,
    isLoading,
    isError,
  };
};
export default useChatItem;
