import Card from "components/atoms/Card";
import { useTranslation } from "react-i18next";
import { HiOutlinePencil } from "react-icons/hi2";

type Props = {
  firstName: string;
  lastName: string;
  iban: string;
  isEditable?: boolean;
};

const PayoutMethodCard = ({ firstName, lastName, iban, isEditable = false }: Props) => {
  const { t } = useTranslation();

  return (
    <Card as="dl" className="relative grid">
      <dt className="sr-only">{t("payment.cardholder")}</dt>
      <dd className="text-left">
        {firstName} {lastName}
      </dd>
      <dt className="sr-only">{t("payment.iban")}</dt>
      <dd className="text-left text-sm text-gray-500">
        {t("payment.iban")}: {iban}
      </dd>
      <HiOutlinePencil className={"absolute right-3 top-2 h-5 w-5"} />
    </Card>
  );
};

export default PayoutMethodCard;
