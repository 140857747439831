import { useFirstline } from "@first-line/firstline-react";
import { Popover, Transition } from "@headlessui/react";
import classNames from "classnames";
import StillUploadingMediaPopup from "components/media/StillUploadingMediaPopup";
import useChat from "hooks/useChat.hook";
import useNavList from "hooks/useNavList.hook";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiBars3, HiOutlineArrowRightOnRectangle, HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import useUploadStore from "state/uploadState";

const SidebarPopUp = () => {
  const { t } = useTranslation();
  const { logout } = useFirstline();
  const navigate = useStatefulNavigate();
  const { moreNavList } = useNavList();
  const { openChatWithMaloumOfficial } = useChat();

  const { uploads } = useUploadStore();

  const [stillUploading, setStillUploading] = useState(false);

  const logoutButton = {
    title: "logOut",
    onClick: () => logout(),
    Icon: HiOutlineArrowRightOnRectangle,
  };

  const supportButton = {
    title: "navigation.support",
    onClick: () => openChatWithMaloumOfficial(),
    Icon: HiOutlineChatBubbleLeftRight,
  };

  const handleNavigate = (to: string) => {
    if (uploads.map((upload) => upload.status).includes("uploading")) {
      setStillUploading(true);
    } else {
      navigate(to);
    }
  };

  const menuItems = [...moreNavList, supportButton, logoutButton];
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              "flex w-full items-center gap-3 rounded-md border-2 border-transparent p-2 text-gray-700 outline-none duration-100 hover:bg-beige-300 hover:text-gray-500 focus:border-blue-violet active:bg-beige-400 active:text-gray-800 disabled:bg-gray-100 disabled:text-gray-300",
              {
                "bg-beige-400": open,
              },
            )}
          >
            <HiBars3 className={"h-6 w-6"} />
            <span className="hidden md:flex">{t("navigation.more")}</span>
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute -left-[1rem] bottom-[3.3125rem] z-[60] mt-5 flex w-screen max-w-min px-4">
              <div className="w-60 shrink rounded-md bg-white p-2 text-gray-700 shadow-lg">
                {menuItems.map((item, i) => {
                  const { title, to, Icon, onClick } = item;
                  return (
                    <div key={title}>
                      {to !== undefined ? (
                        <button
                          onClick={() => handleNavigate(to)}
                          className="flex gap-2 rounded-md border-2 border-transparent p-2 outline-none hover:text-gray-500 focus:border-blue-violet"
                        >
                          <Icon className="h-6 w-6" />
                          {t(title)}
                        </button>
                      ) : (
                        <button
                          onClick={onClick}
                          className="flex w-full gap-2 rounded-md border-2 border-transparent p-2 outline-none hover:text-gray-500 focus:border-blue-violet"
                        >
                          <Icon className="h-6 w-6" />
                          {t(title)}
                        </button>
                      )}
                      {menuItems.length !== i + 1 && <hr className="my-1 border-gray-200" />}
                    </div>
                  );
                })}
              </div>

              <StillUploadingMediaPopup isOpen={stillUploading} setIsOpen={setStillUploading} />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default SidebarPopUp;
