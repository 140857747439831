import { ChatDto, PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import EmptyChatList from "components/chats/EmptyChatList";
import ScheduledBroadcastMessages from "components/chats/ScheduledBroadcastMessages";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import useChats from "hooks/useChats.hook";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineChartBarSquare, HiOutlineCog6Tooth, HiOutlinePencilSquare } from "react-icons/hi2";
import { useInView } from "react-intersection-observer";
import useUserStore from "state/userState";
import ChatItem from "../../components/chats/ChatItem";
import Container from "../../components/layouts/Container";

const LoadingChatItem = () => {
  return (
    <div className="animate-pulse">
      <div className="flex items-center gap-6 py-4">
        <div className="h-12 w-12 shrink-0 rounded-full bg-gray-100" />
        <div className="grow">
          <div className="flex items-center">
            <div className="h-4 w-20 rounded-md bg-gray-100" />
            <div className="grow" />
            <div className="h-3 w-20 rounded-md bg-gray-100" />
          </div>
          <div className="mt-0.5 flex items-center">
            <div className="h-4 w-full rounded-md bg-gray-100" />
          </div>
        </div>
      </div>
      <div className="ml-[74px] h-[2px] w-[calc(100%-74px)] bg-gray-100" />
    </div>
  );
};

const Chats = () => {
  const { t } = useTranslation();

  // ref for loading trigger div
  const { ref, inView } = useInView();

  // chats
  const { chats, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useChats();

  const user = useUserStore<PrivateUserDto>((state) => state.user);

  // create chat list component
  const createItemComponent = useCallback((item: ChatDto) => <ChatItem chatItem={item} key={item._id} />, []);

  // create loading chat list component
  const createLoadingItemComponent = useCallback((key: number) => <LoadingChatItem key={key} />, []);

  // create empty view
  const createEmptyView = useCallback(() => <EmptyChatList />, []);

  // load next page when threshold reached
  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) fetchNextPage();
  }, [inView]);

  return (
    <Layout>
      <HeaderBar>
        <HeaderBar.MainPage>
          <HeaderBar.MainPageHeading>{t("navigation.chat")}</HeaderBar.MainPageHeading>
          {user.isCreator && (
            <HeaderBar.MainPageActions>
              <HeaderBar.Link to={user.isCreator && user.isVerified ? "new" : "/verification/start"}>
                <HiOutlinePencilSquare className="h-6 w-6 text-gray-800" />
              </HeaderBar.Link>
              <HeaderBar.Link to={user.isCreator && user.isVerified ? "broadcasts" : "/verification/start"}>
                <HiOutlineChartBarSquare className="h-6 w-6 text-gray-800" />
              </HeaderBar.Link>
              <HeaderBar.Link to={user.isCreator && user.isVerified ? "access" : "/verification/start"}>
                <HiOutlineCog6Tooth className="h-6 w-6 text-gray-800" />
              </HeaderBar.Link>
            </HeaderBar.MainPageActions>
          )}
        </HeaderBar.MainPage>
      </HeaderBar>

      <Container className="relative bg-white" as="main">
        {user.isCreator && <ScheduledBroadcastMessages />}
        {chats.map(createItemComponent)}
        <div className="absolute bottom-[10rem]" ref={ref} />
        {chats.length === 0 && !isLoading && createEmptyView()}
        {isLoading &&
          Array(5)
            .fill(null)
            .map((_, key) => createLoadingItemComponent(key))}
      </Container>
    </Layout>
  );
};

export default Chats;
