import { useFirstline } from "@first-line/firstline-react";
import { ChatDto, MessageDto, PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext, useEffect, useRef } from "react";
import { QueryFunctionContext, useInfiniteQuery } from "react-query";
import { io } from "socket.io-client";
import useChatsStore from "state/chatsState";
import useMessagesStore from "state/messagesState";
import useUserStore from "state/userState";
import { env } from "../env";
import { messageToMessagePreview } from "helper/messageHelper";

const useChats = () => {
  const { getAccessToken } = useFirstline();
  const { maloumClient } = useContext(MaloumClientContext);
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const chats = useChatsStore((state) => state.chats);
  const chatsRef = useRef<ChatDto[]>([]);
  chatsRef.current = chats;

  const fetchChats = async (next: QueryFunctionContext<string[], any>) => maloumClient.chats.me(next?.pageParam, 15);

  const {
    data: chatsData,
    isLoading: queryIsLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(["fetch-chats"], fetchChats, {
    getNextPageParam: (lastPage) => lastPage.next,
  });

  const addChats = async (chats: ChatDto[]) => {
    // add chats to array
    // we need to use chatsRef as chats can be out of date when this runs
    // process is possibly in a different thread (sockets)

    // filter chats for chats with the newest message
    const filteredChats = chatsRef.current.filter(
      (c1) =>
        !chats.some(
          (c2) =>
            c1._id === c2._id &&
            new Date(c1.messages[0]?.sentAt || c1.createdAt) < new Date(c2.messages[0]?.sentAt || c2.createdAt),
        ),
    );
    // filter chatsNew for chats with the newest message
    const filteredNewChats = chats.filter(
      (c1) =>
        !chatsRef.current.some(
          (c2) =>
            c1._id === c2._id &&
            new Date(c1.messages[0]?.sentAt || c1.createdAt) <= new Date(c2.messages[0]?.sentAt || c2.createdAt),
        ),
    );
    let chatsTmp = [...filteredChats, ...filteredNewChats];
    chatsTmp = chatsTmp.sort(
      // @ts-ignore sort by date
      (c1, c2) => new Date(c2.messages[0]?.sentAt || c2.createdAt) - new Date(c1.messages[0]?.sentAt || c1.createdAt),
    );

    // update chats
    useChatsStore.setState({ chats: chatsTmp });
  };

  const replaceChat = async (chat: ChatDto) => {
    // abort if last message is broadcasted and sender is user
    if (chat.messages[0] && chat.messages[0].isBroadcasted && chat.messages[0].senderId === user._id) return;
    // filter chats for chats with the newest message
    const filteredChats = chatsRef.current.filter((c1) => c1._id !== chat._id);

    let chatsTmp = [...filteredChats, chat];
    chatsTmp = chatsTmp.sort(
      // @ts-ignore sort by date
      (c1, c2) => new Date(c2.messages[0]?.sentAt || c2.createdAt) - new Date(c1.messages[0]?.sentAt || c1.createdAt),
    );

    // update chats
    useChatsStore.setState({ chats: chatsTmp });
  };

  useEffect(() => {
    if (!chatsData) {
      useChatsStore.setState({ chats: [] });
    } else addChats(chatsData.pages.flatMap((p) => p.data));
  }, [chatsData]);

  const addOrUpdateChatByMessage = async (message: MessageDto) => {
    const chat = await maloumClient.chats.getChat(message.chat);
    replaceChat(chat);
  };

  const removeMessageFromChat = async (message: MessageDto) => {
    // check if chat exists
    const chatIdx = chatsRef.current.findIndex((c) => c._id === message.chat);
    // abort if chat does not exist
    if (chatIdx === -1) return;
    // abort if message is not the last message
    if (chatsRef.current[chatIdx].messages.findIndex((m) => m._id === message._id) === -1) return;

    // check messageStore for past messages
    const messagesForChat = useMessagesStore.getState().messages[message.chat];
    // if message is in messageStore, update chat with message store
    if (messagesForChat !== undefined && messagesForChat.filter((m) => m._id === message._id).length !== 0) {
      const chatsTmp = chatsRef.current;
      chatsTmp[chatIdx].messages = [messageToMessagePreview(messagesForChat.filter((m) => m._id !== message._id)[0])];
      useChatsStore.setState({ chats: chatsTmp });
    } else {
      // fetch chat from backend to get last relavant message
      const chat = await maloumClient.chats.getChat(message.chat);
      const chatsTmp = chatsRef.current;

      if (chat.messages.length !== 0) {
        // update chat if messages are left
        chatsTmp[chatIdx] = chat;
      } else {
        // delete chat if no messages are left
        delete chatsTmp[chatIdx];
      }
      useChatsStore.setState({ chats: chatsTmp });
    }
  };

  const connectWebSocket = async () => {
    // connect socket
    const socket = io(env.VITE_NEOLIME_WS_BACKEND_URL, {
      upgrade: true,
      transports: ["websocket"],
      auth: {
        authorization: `Bearer ${await getAccessToken()}`,
      },
    });

    // register relevant events
    socket.on("connect", () => socket.emit("authenticate", "ack"));
    socket.on("receive_message", addOrUpdateChatByMessage);
    socket.on("delete_message_update", removeMessageFromChat);

    return socket;
  };

  // setup websocket and disconnect on close
  useEffect(() => {
    const setUpWebsocket = async () => {
      return connectWebSocket();
    };

    const socket = setUpWebsocket();

    return () => {
      socket.then((socket) => {
        socket.off("connect");
        socket.off("receive_message");
        socket.disconnect();
      });
    };
  }, []);

  return { chats, isLoading: queryIsLoading, isFetchingNextPage, hasNextPage, fetchNextPage };
};

export default useChats;
