import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import CreatedBy from "components/CreatedBy";
import StickyBottomBar from "components/StickyBottomBar";
import Pill from "components/atoms/Pill";
import LinkifiedText, { canUserDisplayLinks } from "components/atoms/utils/LinkifiedText";
import Button from "components/basics/Button";
import ProfileImage from "components/basics/ProfileImage";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import Media from "components/media/Media";
import ContentReportPopUp from "components/product/ContentReportPopUp";
import ProductPopup from "components/product/ProductPopup";
import OrderType from "enums/OrderType";
import useCurrency from "hooks/useCurrency.hook";
import useProduct from "hooks/useProduct.hook";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { usePostHog } from "posthog-js/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiEllipsisHorizontal } from "react-icons/hi2";
import { createSearchParams, useParams } from "react-router-dom";
import useUserStore from "state/userState";

const ProductDetailPage = () => {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const { displayCurrency } = useCurrency();
  const navigate = useStatefulNavigate();

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const { productId } = useParams();
  const { product, isLoading } = useProduct(productId || "");

  const user = useUserStore<PrivateUserDto>((state) => state.user);

  const isOwnProduct = useMemo(() => user._id === product?.createdBy._id, [user, product]);

  const goToCheckout = useCallback(() => {
    // POSTHOG: emmit go to product checkout event
    if (product) posthog?.capture("checkout-product", { product: product._id, creator: product.createdBy.username });
    // Google Tag Manager: Add To Cart Event
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: "add_to_cart",
      items: [
        {
          item_id: product?._id,
          item_name: product?.name,
          net_price: product?.price.net,
          interests: product?.categories.map((category: { _id: string }) => category._id),
          creator_id: product?.createdBy._id,
        },
      ],
    });
    navigate({
      pathname: "/checkout",
      search: `?${createSearchParams({
        type: OrderType.Product,
        id: productId ?? "",
      })}`,
    });
  }, [product, productId, createSearchParams, navigate, posthog]);

  const isInReview = useMemo(() => product?.reviewed === false, [product?.reviewed]);

  // POSTHOG: emmit product view event
  useEffect(() => {
    if (product) {
      posthog?.capture("viewed-product", { product: product._id, creator: product.createdBy.username });
    }
  }, [product]);

  useEffect(() => {
    if (product && !isLoading) {
      // Google Tag Manager: View Item Event
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        event: "view_item",
        items: [
          {
            item_id: product._id,
            item_name: product.name,
            net_price: product.price.net,
            interests: product.categories.map((category: { _id: string }) => category._id),
            creator_id: product.createdBy._id,
          },
        ],
      });
    }
  }, [product, isLoading]);

  if (isLoading || !product) {
    return (
      <Layout hideNavigationMobile hasStickyBottom>
        <HeaderBar>
          <HeaderBar.SubPage>
            <HeaderBar.Left>
              <HeaderBar.BackButton />
            </HeaderBar.Left>
            <HeaderBar.Center>
              <HeaderBar.Title>{t("product.title")}</HeaderBar.Title>
            </HeaderBar.Center>
            <HeaderBar.Right />
          </HeaderBar.SubPage>
        </HeaderBar>

        <Container hasPadding={false} hasGrow={false}>
          <div className={"mb-10 h-64 w-full animate-pulse bg-gray-200"} />
        </Container>

        <Container hasStickyBottom>
          <div className={"mb-2 h-3 w-1/3 animate-pulse rounded-full bg-gray-200"} />
          {Array.from({ length: 4 }).map((_, i) => (
            <div key={i} className={"mb-1 h-2 w-full animate-pulse rounded-full bg-gray-200"} />
          ))}
          <div className={"mb-1 h-2 w-3/4 animate-pulse rounded-full bg-gray-200"} />

          <div className={"mt-6 flex items-center"}>
            <ProfileImage isLoading className={"h-12 w-12"} />
            <div className={"flex flex-col pl-3"}>
              <div className={"mb-1 h-3 w-32 animate-pulse rounded-full bg-gray-200"} />
              <div className={"h-2 w-20 animate-pulse rounded-full bg-gray-200"} />
            </div>
          </div>
        </Container>

        <StickyBottomBar hasSeparator>
          <div className="grid grid-cols-2 items-center justify-between">
            <div className={"h-3 w-20 animate-pulse rounded-full bg-gray-200"} />
            <div className="flex h-10 animate-pulse rounded-md bg-gray-200" />
          </div>
        </StickyBottomBar>
      </Layout>
    );
  }

  return (
    <Layout hideNavigationMobile hideVerificationButton hasStickyBottom>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.BackButton />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("product.title")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right>
            <HeaderBar.Button onClick={() => setIsPopupOpen(true)}>
              <HiEllipsisHorizontal className={"h-6 w-6"} />
            </HeaderBar.Button>
          </HeaderBar.Right>
        </HeaderBar.SubPage>
      </HeaderBar>
      {isOwnProduct ? (
        <ProductPopup
          open={isPopupOpen}
          setOpen={setIsPopupOpen}
          product={product}
          deletedCallback={() => navigate(`/creator/${user.username}?tab=products`)}
        />
      ) : (
        <ContentReportPopUp id={product._id} isProduct isOpen={isPopupOpen} setIsOpen={setIsPopupOpen} />
      )}
      {/* special case because of  full width image */}
      <Container hasPadding={false} hasGrow={false} className="h-full justify-between overflow-hidden">
        <div className="flex flex-1 flex-col overflow-auto">
          <div className="relative aspect-[1.5/1] w-full bg-beige-400">
            <Media
              media={product.media[0]}
              thumbnail={product.thumbnail}
              cover={false}
              data-testid="product-detail-image"
            />
          </div>

          <div className="p-4">
            <div className="flex justify-between">
              <LinkifiedText
                className={"notranslate mb-2 text-base"}
                tag={"h2"}
                displayAsLink={canUserDisplayLinks(product.createdBy)}
              >
                {product.name}
              </LinkifiedText>
              {isInReview && (
                <div className="ml-2">
                  <Pill className="notranslate">{t("content.inReview")}</Pill>
                </div>
              )}
            </div>

            <LinkifiedText
              className={"notranslate mb-4 text-sm"}
              tag={"p"}
              displayAsLink={canUserDisplayLinks(product.createdBy)}
            >
              {product.description}
            </LinkifiedText>
            <CreatedBy user={product.createdBy} />
          </div>
        </div>
      </Container>

      <StickyBottomBar hasSeparator>
        <div className="grid grid-cols-2 items-center justify-between">
          <span className="font-medium" data-testid={"product-detail-price"}>
            {displayCurrency(product.price.net)}
          </span>
          {!isOwnProduct && <Button text={t("buyNow")} onClick={goToCheckout} />}
        </div>
      </StickyBottomBar>
    </Layout>
  );
};

export default ProductDetailPage;
