import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { Fragment, ReactNode } from "react";

type Props = {
  title: string;
  defaultIcon?: ReactNode;
  activeIcon?: ReactNode;
  isLoading?: boolean;
};

const MyTab = ({ title, defaultIcon, activeIcon, isLoading = false }: Props) => {
  return (
    <Tab as={Fragment}>
      {({ selected }) =>
        isLoading ? (
          <div
            className={
              "flex h-12 flex-1 animate-pulse items-center justify-center border-b text-gray-300 outline-none transition-all"
            }
          >
            {defaultIcon}
            <div className={"h-3 w-14 rounded-full bg-gray-200"} />
          </div>
        ) : (
          <button
            className={classNames("flex h-12 flex-1 items-center justify-center border-b outline-none transition-all", {
              "border-b-gray-700 text-gray-700": selected,
              "border-gray-100 text-gray-500": !selected,
            })}
          >
            {selected ? activeIcon : defaultIcon}
            {title}
          </button>
        )
      }
    </Tab>
  );
};

export default MyTab;
