import useUnreadChats from "hooks/useUnreadChats.hook";
import { useMemo } from "react";
import { HiChatBubbleOvalLeft, HiOutlineChatBubbleOvalLeft } from "react-icons/hi2";

type ChatNavIconType = {
  selected: boolean;
};

const ChatNavIcon = ({ selected = false }: ChatNavIconType) => {
  const { unreadMessagesNumber } = useUnreadChats();

  const displayedUnreadMessagesNumber = useMemo(
    () => (unreadMessagesNumber > 9 ? "9+" : unreadMessagesNumber),
    [unreadMessagesNumber],
  );

  return (
    <div className={"relative"}>
      {unreadMessagesNumber > 0 && (
        <div
          className={
            "absolute -right-1 -top-0.5 flex h-[1.125rem] w-[1.125rem] items-center justify-center rounded-full bg-red-900 p-0.5 text-xs text-white"
          }
        >
          {displayedUnreadMessagesNumber}
        </div>
      )}
      {selected ? (
        <HiChatBubbleOvalLeft className={"h-7 w-7 sm:h-6 sm:w-6"} />
      ) : (
        <HiOutlineChatBubbleOvalLeft className={"h-7 w-7 sm:h-6 sm:w-6"} />
      )}
    </div>
  );
};

const ChatNavIconSelected = () => <ChatNavIcon selected />;

export { ChatNavIcon, ChatNavIconSelected };
