import Button from "components/basics/Button";
import PopUp from "components/popup/PopUp";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

type Props = {
  chatId: string;
  userId: string;
  open: boolean;
  onClose: () => void;
  "data-testid"?: string;
};

const UnblockUserPopup = ({ chatId, userId, open, onClose, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { maloumClient } = useContext(MaloumClientContext);

  const handleUnblockUser = useMutation(() => maloumClient.users.unblockUsers({ userIds: [userId] }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-chat", chatId]);
      onClose();
    },
  });

  return (
    <PopUp isOpen={open} onClose={onClose} title={t("chatSettings.block.unblockUser") ?? ""} data-testid={dataTestId}>
      <div className="mt-2">{t("chatSettings.block.unblockUserConfirmation")}</div>
      <div className="mt-4 flex items-center gap-2">
        <Button text={t("cancel")} variant="secondary" onClick={onClose} disabled={handleUnblockUser.isLoading} />
        <Button
          text={t("chatSettings.block.unblock")}
          onClick={handleUnblockUser.mutate}
          disabled={handleUnblockUser.isLoading}
        />
      </div>
    </PopUp>
  );
};

export default UnblockUserPopup;
