import { CheckoutTipDto, PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import Button from "components/basics/Button";
import NumberInput from "components/basics/NumberInput";
import PopupBottom from "components/molecules/PopupBottom";
import MaloumClientContext from "contexts/MaloumClientContext";
import OrderType from "enums/OrderType";
import { Form, Formik } from "formik";
import useCurrency from "hooks/useCurrency.hook";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { createSearchParams } from "react-router-dom";
import useUserStore from "state/userState";
import * as Yup from "yup";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import useCheckout from "hooks/checkout/useCheckout.hook";
import useStoredCreditCard from "hooks/checkout/useStoredCreditCard.hook";
import { getThreeDSBrowserData } from "helper/checkoutHelper";

type Props = {
  creatorId: string;
  open: boolean;
  setOpen: (v: boolean) => void;
  className?: string;
  "data-testid"?: string;
};

const TipPopupBottom = ({ creatorId, open, setOpen, className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const { displayCurrency } = useCurrency();
  const { maloumClient } = useContext(MaloumClientContext);
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const [tip, setTip] = useState("");

  const storedCreditCard = useStoredCreditCard(user);

  const { price } = useCheckout(creatorId, "TIP", parseFloat(tip), user.address.country, storedCreditCard);

  const checkoutTip = () => {
    if (tip)
      navigate({
        pathname: "/checkout",
        search: createSearchParams({
          type: OrderType.Tip,
          amount: tip.toString().replaceAll(",", "."),
          id: creatorId,
        }).toString(),
      });
  };

  const handleBuyTip = async () => {
    if (!tip) return;
    const body: CheckoutTipDto = {
      netAmount: tip as unknown as number,
      creatorId: creatorId,
      address: { country: user.address.country },
      paymentMethod: {
        paymentMethod: user.storedPaymentInformation[0].paymentMethod,
        referenceId: user.storedPaymentInformation[0].referenceUUID,
      },
      // @ts-ignore
      threeDSBrowserData: getThreeDSBrowserData(),
    };
    await maloumClient.checkout.checkoutTip(body).then((r) => window.location.replace(r.redirectUrl));
  };

  const handleBuyTipMutation = useMutation(handleBuyTip);

  return (
    <PopupBottom
      open={open}
      setOpen={setOpen}
      className={className}
      data-testid={dataTestId}
      title={t("tips.send") as string}
    >
      <div className="mb-4 text-2xl font-semibold sm:hidden">{t("tips.send")}</div>
      <Formik
        initialValues={{
          tip: tip,
        }}
        validateOnMount={true}
        validationSchema={Yup.object().shape({
          tip: Yup.number()
            .typeError(t("validation.onlyNumbers") as string)
            .min(1, ({ min }) => t("validation.minValue", { minValue: min }))
            .max(9999, ({ max }) => t("validation.maxValue", { maxValue: max }))
            .test("is-decimal", t("validation.only2Decimals") as string, (val) => {
              if (val !== undefined) {
                return /^\d+(\.\d{0,2})?$/.test(val.toString());
              }
              return true;
            })
            .required(t("validation.required") as string),
        })}
        onSubmit={() => {}}
      >
        {({ isValid }) => (
          <Form className="mt-2 w-full">
            <div className="mt-4">{t("tips.chooseAmount")}</div>
            <NumberInput
              name="tip"
              spacing="mb-0"
              prefix="€"
              inputMode="decimal"
              // @ts-ignore
              onChange={setTip}
              placeholder={t("tips.placeholder") as string}
            />
            <p className="mt-1 text-xs text-gray-500">
              {t("tips.chooseAmountBetween", {
                min: displayCurrency(1),
                max: displayCurrency(9999),
              })}
            </p>

            {tip !== "" && storedCreditCard ? (
              <>
                {price ? (
                  <div className="mt-3">
                    {price.paymentFeeAmount > 0 && (
                      <div className="flex w-full justify-between text-xs text-gray-400 xs:text-sm">
                        <span>{t("checkout.serviceFee")}</span>
                        <span>{displayCurrency(price.paymentFeeAmount)}</span>
                      </div>
                    )}
                    <div className="flex w-full justify-between text-xs text-gray-400 xs:text-sm">
                      <span>{t("checkout.VAT")}</span>
                      <span>{displayCurrency(price.vatAmount)}</span>
                    </div>
                    <div className="mt-0.5 flex w-full justify-between text-xl font-semibold">
                      <span>{t("checkout.total")}</span>
                      <span>{displayCurrency(price.gross)}</span>
                    </div>
                  </div>
                ) : (
                  <div className="mt-3">
                    <div className="mt-1 flex w-full justify-between">
                      <span className="h-4 w-20 rounded-md bg-gray-100" />
                      <span className="h-4 w-20 rounded-md bg-gray-100" />
                    </div>
                    <div className="mt-1 flex w-full justify-between">
                      <span className="h-4 w-20 rounded-md bg-gray-100" />
                      <span className="h-4 w-20 rounded-md bg-gray-100" />
                    </div>
                    <div className="mt-1 flex w-full justify-between">
                      <span className="h-6 w-20 rounded-md bg-gray-100" />
                      <span className="h-6 w-24 rounded-md bg-gray-100" />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <p className="mt-3 text-left text-xs text-gray-500">*{t("tips.additionalVat")}</p>
            )}
            {/*user.storedPaymentInformation[0] ? (
              <>
                <Button
                  type="submit"
                  text={
                    handleBuyTipMutation.isLoading
                      ? undefined
                      : t("tips.sendTipWith", { hint: user.storedPaymentInformation[0]?.hint })
                  }
                  IconFront={
                    handleBuyTipMutation.isLoading
                      ? () => <AiOutlineLoading3Quarters className="my-0.5 h-5 w-5 animate-spin" />
                      : undefined
                  }
                  className="mt-4"
                  onClick={handleBuyTipMutation.mutate}
                  disabled={!isValid || !price || handleBuyTipMutation.isLoading}
                />
                <TernaryButton
                  type="submit"
                  className="mt-2 w-full text-sm"
                  onClick={checkoutTip}
                  disabled={!isValid || !price || handleBuyTipMutation.isLoading}
                >
                  <div className="w-full text-center">{t("tips.chooseDifferentPaymentMethod")}</div>
                </TernaryButton>
              </>
            ) : (
              <Button type="submit" text={t("tips.send")} className="mt-4" onClick={checkoutTip} disabled={!isValid} />
            )*/}
            <Button type="submit" text={t("tips.send")} className="mt-4" onClick={checkoutTip} disabled={!isValid} />
          </Form>
        )}
      </Formik>
    </PopupBottom>
  );
};

export default TipPopupBottom;
